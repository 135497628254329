import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles((theme) => ({
  container: {
    width: "400px",
    padding: 0,
    borderBottom: `1px solid ${theme.palette.gray.medium}`,
    textAlign: "left",
  },
  header: {
    padding: `0 ${theme.spacing(2)} ${theme.spacing(2.5)} ${theme.spacing(2)}`,
    borderBottom: `1px solid ${theme.palette.gray.medium}`,
  },
  title: {
    color: theme.palette.blue.dark,
  },
  content: {
    padding: theme.spacing(2),
  },
  directions: {
    color: theme.palette.blue.dark,
    textAlign: "left",
    whiteSpace: "pre-line",
  },
  form: {
    marginTop: theme.spacing(2.5),
  },
  label: {
    color: theme.palette.blue.dark,
    textAlign: "left",
    marginBottom: theme.spacing(1),
    fontSize: "1rem",
  },
  middleLabel: {
    marginTop: theme.spacing(2.5),
  },
  learnLink: {
    fontWeight: 600,
    textDecoration: "underline",
    color: theme.palette.blue.dark,
  },
  preview: {
    height: "250px",
    marginTop: theme.spacing(1.25),
    border: `1px solid ${theme.palette.blue.dark}`,
    borderRadius: "5px",
    cursor: "not-allowed",
    userSelect: "none",
  },
  previewIframe: {
    height: "100%",
    width: "100%",
    borderRadius: "5px",
    pointerEvents: "none",
  },
}));

export default useStyles;
