import { useState, useRef, useCallback, memo, MouseEventHandler } from "react";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { ListItem } from "@remo-co/ui-core/src/components/ListItem";
import { ListItemAvatar } from "@remo-co/ui-core/src/components/ListItemAvatar";
import { ListItemIcon } from "@remo-co/ui-core/src/components/ListItemIcon";
import { ListItemSecondaryAction } from "@remo-co/ui-core/src/components/ListItemSecondaryAction";
import { ListItemText } from "@remo-co/ui-core/src/components/ListItemText";
import { Tooltip } from "@remo-co/ui-core/src/components/Tooltip";
import { MoreVert } from "@remo-co/ui-core/src/icons/MoreVert";
import { Chat as ChatIcon } from "@remo-co/ui-core/src/icons/Chat";
import { Error as ErrorIcon } from "@remo-co/ui-core/src/icons/Error";
import RaiseHandIcon from "modules/eventButtons/assets/RaiseHand.png";
import { PresentModeIcon } from "modules/eventButtons/icons/PresentModeIcon";
import useBroadcasterActions from "modules/broadcaster/hooks/useBroadcasterActions";
import { InviteToStageStatus, selectBroadcasters } from "modules/broadcaster";
import useRaiseHandActions from "modules/broadcastRaiseHand/hooks/useRaiseHandActions";
import Avatar from "modules/userAvatar/Avatar";
import { useSendMessage } from "modules/chat";
import { useI18n } from "i18n";
import { useSelector } from "react-redux";
import logger from "logging/logger";
import { selectCurrentTheaterId } from "modules/theater/selectors";
import { selectIsChatEnabled } from "modules/event/selectors";
import { selectIsBroadcasting } from "modules/broadcast/redux/selectors";
import { selectUser } from "modules/auth/redux/selectors";
import { getUserName } from "modules/userProfile";
import { usePermissions } from "modules/audioVideo/hooks/usePermissions";
import { ActivityStatusIndicator } from "modules/eventActivityStatus/components/ActivityStatusIndicator";
import { addDialogNotification } from "modules/dialogNotification/redux/dialogNotificationSlice";
import { useAppDispatch } from "store/hooks";
import { UserItem } from "../../types";
import { useClickOutside } from "../../hooks";
import { UserDropdownActions } from "../../components";
import { useStyles } from "./styles";
import { ParticipantGroup } from "../../hooks/useParticipantGroups/useParticipantGroups";
import { LocateParticipantButton } from "../LocateParticipantButton";

interface Props {
  user: UserItem;
  group: ParticipantGroup;
  switchChatTab?: () => void;
  onClick?: MouseEventHandler<HTMLLIElement>;
}

const UserRow = ({ user, group, switchChatTab, onClick }: Props) => {
  const { t } = useI18n(["common", "event", "micCamCheck", "participants"]);
  const isBroadcasting = useSelector(selectIsBroadcasting);
  const { startDirectMessage } = useSendMessage();
  const { toggleRaiseHand } = useRaiseHandActions();
  const { giveBroadcastRight, takeBroadcastRight } = useBroadcasterActions();
  const dispatch = useAppDispatch();
  const loginUser = useSelector(selectUser);
  const [isDropdownActionsOpen, setDropdownActionsOpen] = useState(false);
  const theaterId = useSelector(selectCurrentTheaterId);
  const name = getUserName(user);
  const picture = user.profile ? user.profile.picture : null;
  const elementRef = useRef<HTMLButtonElement | null>(null);
  const isChatEnabled = useSelector(selectIsChatEnabled);
  const styles = useStyles();
  const broadcasters = useSelector(selectBroadcasters);
  const isSelf = user.id === loginUser?.id;
  const userHasPublishError =
    broadcasters[user.id]?.status === InviteToStageStatus.ERROR;
  const { revokeSpeakerPermission } = usePermissions();

  const handleMoreClick = useCallback(() => {
    setDropdownActionsOpen(!isDropdownActionsOpen);
  }, [isDropdownActionsOpen]);

  const handUnRaiseHand = useCallback(() => {
    if (theaterId) {
      toggleRaiseHand(theaterId, user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, theaterId]);

  const handleChatWithUser = useCallback(() => {
    if (isSelf || !loginUser?.id) return;

    switchChatTab?.();
    startDirectMessage(user.id, loginUser.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loginUser, isSelf]);

  const closeDropdownActions = () => setDropdownActionsOpen(false);
  const clickOutsideRef = useClickOutside(closeDropdownActions);

  const allowBroadcast = useCallback(
    (userId: string, userName: string) => {
      if (!theaterId) {
        return;
      }
      dispatch(
        addDialogNotification({
          hideCloseButton: true,
          className: "dark",
          message: t("event:user.broadcast.ability"),
          title: t("event:title.grant.broadcast.permission"),
          confirmText: t("yes"),
          dismissText: t("no"),
          onConfirm: () => {
            if (user.isRaisedHand) {
              toggleRaiseHand(theaterId, user.id);
            }
            giveBroadcastRight({
              theaterId,
              userId,
              userName,
              // eslint-disable-next-line promise/prefer-await-to-then
            }).catch((error) => {
              logger.error(`[UserRow][giveBroadcastRight] ${error.message}`);
            });
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theaterId, user.id, user.isAllowBroadcast],
  );

  const disallowBroadcast = useCallback(
    (userId: string, userName: string) => {
      if (!theaterId) {
        return;
      }

      dispatch(
        addDialogNotification({
          hideCloseButton: true,
          className: "dark",
          message: t("event:user.revert.broadcast"),
          title: t("please.confirm"),
          onConfirm: () => {
            revokeSpeakerPermission(userId);

            takeBroadcastRight({
              theaterId,
              userId,
              userName,
              // eslint-disable-next-line promise/prefer-await-to-then
            }).catch((error) => {
              logger.error(`[UserRow][takeBroadcastRight] ${error.message}`);
            });
          },
        }),
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theaterId],
  );

  const handleToggleBroadcast = () =>
    user.isAllowBroadcast
      ? disallowBroadcast(user.id, getUserName(user))
      : allowBroadcast(user.id, getUserName(user));

  let toggleBroadcastTitle = "";

  if (user.canToggleBroadcastRight && user.isAllowBroadcast) {
    toggleBroadcastTitle = t("event:make.attendee");
  } else if (user.canToggleBroadcastRight && !user.isAllowBroadcast) {
    toggleBroadcastTitle = t("event:make.speaker");
  }

  return (
    <div ref={clickOutsideRef} data-testid={`user-row-${group}-${user.id}`}>
      <ListItem onClick={onClick} className={styles.listItem}>
        {picture && (
          <ListItemAvatar>
            <Avatar
              data-testid="user-row-avatar"
              className={styles.bcuAvatar}
              user={user}
              alt={`${name}${isSelf ? t("micCamCheck:name.me") : ""}`}
            />
          </ListItemAvatar>
        )}
        <div>
          <ListItemText
            className={styles.userName}
            primary={`${name || "No name"}${
              isSelf
                ? ` (Me${name ? "" : t("micCamCheck:please.update.profile")})`
                : ""
            }`}
          />
          <ActivityStatusIndicator userId={user.id} small />
        </div>
        <ListItemSecondaryAction>
          <LocateParticipantButton user={user} />
          {isBroadcasting && user.isRaisedHand && (
            <Tooltip
              title={t("lower.hand")}
              placement="top"
              disableHoverListener={!user.canUnraiseHand}
            >
              <IconButton
                className={styles.raiseHandButton}
                disabled={!user.canUnraiseHand}
                onClick={handUnRaiseHand}
                size="large"
              >
                <img src={RaiseHandIcon} alt={t("raise.hand")} />
              </IconButton>
            </Tooltip>
          )}
          {isChatEnabled && (
            <Tooltip title={t("message.user")} placement="top">
              <IconButton
                disabled={isSelf}
                onClick={handleChatWithUser}
                size="large"
              >
                <ChatIcon
                  className={styles.colorInherit}
                  width="30"
                  height="30"
                />
              </IconButton>
            </Tooltip>
          )}
          {isBroadcasting &&
            (userHasPublishError ? (
              <Tooltip
                title={t("event:broadcaster.mic.cam.issues")}
                placement="top"
              >
                <ListItemIcon className={styles.errorIcon}>
                  <ErrorIcon color="error" data-testid="error-icon" />
                </ListItemIcon>
              </Tooltip>
            ) : (
              <Tooltip title={toggleBroadcastTitle} placement="top">
                <IconButton
                  disabled={!user.canToggleBroadcastRight || user.manager}
                  onClick={handleToggleBroadcast}
                  data-testid="present-mode-button"
                  size="large"
                >
                  <PresentModeIcon data-testid="present-icon" />
                </IconButton>
              </Tooltip>
            ))}

          <Tooltip title={t("more.actions")} placement="top">
            <IconButton
              className={styles.moreBtn}
              data-testid="more-btn"
              disabled={isSelf}
              onClick={handleMoreClick}
              ref={elementRef}
              size="large"
            >
              <MoreVert />
            </IconButton>
          </Tooltip>
        </ListItemSecondaryAction>
      </ListItem>
      <UserDropdownActions
        isOpen={isDropdownActionsOpen}
        anchorElementRef={elementRef}
        user={user}
        toggleBroadcast={handleToggleBroadcast}
        sendMessage={handleChatWithUser}
        closePopper={closeDropdownActions}
      />
    </div>
  );
};

export default memo(UserRow);
