import { SyntheticEvent, useEffect } from "react";
import { Badge } from "@remo-co/ui-core/src/components/Badge";
import { IconButton } from "@remo-co/ui-core/src/components/IconButton";
import { Tab } from "@remo-co/ui-core/src/components/Tab";
import { Tabs } from "@remo-co/ui-core/src/components/Tabs";
import { Box } from "@remo-co/ui-core/src/components/Box";
import { ArrowForward } from "@remo-co/ui-core/src/icons/ArrowForward";
import { useQAActions } from "modules/qa";
import { useI18n } from "i18n";
import { updateActiveChannel } from "modules/chat/redux/actions";
import { useAppDispatch } from "store/hooks";
import { ITab } from "../RightPanel";
import useStyles from "./styles";

interface Props {
  theaterId: string;
  tabId: number;
  handleTabChange: (event: SyntheticEvent<Element, Event>, tab: number) => void;
  onHideRightPanel: () => void;
  tabs: ITab[];
}

const Header = ({
  theaterId,
  tabId,
  handleTabChange,
  onHideRightPanel,
  tabs,
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const { subscribe, unsubscribe } = useQAActions();
  const { t } = useI18n();
  const styles = useStyles();

  useEffect(() => {
    if (!theaterId) return undefined;

    subscribe(theaterId);
    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theaterId]);

  const renderTabHeaders = () =>
    tabs.map((tab) => (
      <Tab
        className={styles.tab}
        label={
          <Badge color="error" badgeContent={tab.badgeContent}>
            <span>{t(tab.label)}</span>
          </Badge>
        }
        id={tab.id}
        key={`tab-header-${tab.id}`}
        data-testid={tab.id}
      />
    ));

  const handleBack = () => {
    dispatch(updateActiveChannel(null));
    onHideRightPanel();
  };

  return (
    <Box display="flex" alignItems="center" height={80} position="relative">
      <IconButton
        className={styles.hidePanel}
        data-dd-action-name="Close button - Right Panel"
        data-testid="hide-panel-button"
        id="bc-hide-panel"
        onClick={handleBack}
        size="large"
      >
        <ArrowForward />
      </IconButton>
      <Tabs
        classes={{
          flexContainer: styles.tabs,
          indicator: styles.indicator,
        }}
        value={tabId}
        textColor="primary"
        onChange={handleTabChange}
      >
        {renderTabHeaders()}
      </Tabs>
    </Box>
  );
};

export default Header;
