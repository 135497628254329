import { useSelector } from "react-redux";
import { useAppDispatch } from "store/hooks";
import { Badge } from "@remo-co/ui-core/src/components/Badge";
import { ActionButton } from "modules/actionButton";
import { useI18n } from "i18n";
import { selectWaitingGuests } from "modules/lobby/redux/selectors";
import { ReactComponent as LobbyIcon } from "./icons/lobby-icon.inline.svg";
import { setActiveMenu, SideMenus, selectIsLobbyMenuOpen } from "../../redux";

interface Props {
  isSmallButton?: boolean;
  darkMode?: boolean;
}

const LobbyButton = ({ isSmallButton, darkMode }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const guests = useSelector(selectWaitingGuests);
  const isLobbyWindowOpen = useSelector(selectIsLobbyMenuOpen);
  const { t } = useI18n();

  return (
    <ActionButton
      title={t("view.guest.lobby")}
      data-testid="lobby-button"
      onClick={() => dispatch(setActiveMenu(SideMenus.LOBBY))}
      label={t("lobby")}
      isActive={isLobbyWindowOpen}
      isPopoverButton={isSmallButton}
      darkMode={darkMode}
      icon={
        <Badge badgeContent={guests.length} color="error">
          <LobbyIcon />
        </Badge>
      }
    />
  );
};

export default LobbyButton;
