import { useState, useRef, useMemo } from "react";
import { Typography } from "@remo-co/ui-core/src/components/Typography";
import { Popper } from "@remo-co/ui-core/src/components/Popper";
import { Card } from "@remo-co/ui-core/src/components/Card";
import { Error } from "@remo-co/ui-core/src/icons/Error";
import { useI18n } from "i18n";
import useStyles from "./styles";

interface Props {
  hasAssignedTables: boolean;
  hasFloorContent: boolean;
}

const LockedAttendeeInputPopover = ({
  hasAssignedTables,
  hasFloorContent,
}: Props) => {
  const iconRef = useRef<SVGSVGElement | null>(null);
  const [open, setOpen] = useState(false);
  const { t } = useI18n(["manageEvent"]);
  const styles = useStyles();

  const handleMouseEnter = () => {
    setOpen(true);
  };
  const handleMouseLeave = () => {
    setOpen(false);
  };

  const reasons = useMemo(
    () =>
      [
        ...(hasAssignedTables
          ? [t("locked.expected.attendees.assign.tables")]
          : []),
        ...(hasFloorContent
          ? [t("locked.expected.attendees.floor.content")]
          : []),
      ].join(", "),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasAssignedTables, hasFloorContent],
  );

  if (!hasAssignedTables && !hasFloorContent) {
    return null;
  }

  return (
    <>
      <Error
        data-testid="error-icon"
        ref={iconRef}
        className={styles.icon}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      />
      <Popper
        open={open}
        anchorEl={iconRef.current}
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: {
                  offset: [5, 5],
                },
              },
            },
          ],
        }}
        placement="bottom-start"
        className={styles.popper}
      >
        <Card className={styles.container} size="sm">
          <Typography variant="body2">
            {t("locked.expected.attendees")} {reasons}.
          </Typography>
        </Card>
      </Popper>
    </>
  );
};

export default LockedAttendeeInputPopover;
