import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles((theme) => ({
  container: {
    padding: `${theme.spacing(2.5)} ${theme.spacing(4)}`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: "#FAFAFA",
    marginTop: theme.spacing(2),
    borderRadius: "10px",
    gap: theme.spacing(1),
  },
  count: {
    marginTop: theme.spacing(1),
  },
  bottomBar: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    marginTop: theme.spacing(1),
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteDialogActions: {
    margin: "0 !important",
    paddingBottom: `${theme.spacing(2)} !important`,
  },
  deleteDialogActionButtons: {
    width: "100%",
  },
}));
