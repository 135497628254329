import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

export const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    width: theme.spacing(62.625),
    borderRadius: theme.spacing(2.5),
    padding: `${theme.spacing(1.25)} 0`,
  },
  closeButton: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(4),
  },
  header: {
    padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  },
  promptToSave: {
    border: `1px solid #E7F3FE`,
    borderRadius: "5px",
    padding: theme.spacing(1.5),
    margin: `${theme.spacing(1.5)} 0`,
    background: "#E7F3FE",
    alignItems: "flex-start",
  },
  promptIcon: {
    color: theme.palette.primary.light,
  },
  message: {
    marginLeft: theme.spacing(2),
  },
  actions: {
    justifyContent: "space-between",
    padding: `${theme.spacing(0.5)} ${theme.spacing(2.5)}`,
  },
  infoFont: {
    fontSize: theme.spacing(1.5),
  },
  buttons: {
    width: theme.spacing(38),
    height: theme.spacing(5),
    fontSize: theme.spacing(1.75),
    fontWeight: 600,
  },
  formInput: {
    margin: `${theme.spacing(2)} 0 `,
  },
  helperText: {
    color: "#88909F",
  },
  inputClass: {
    "&::placeholder": {
      color: "#88909F",
    },
  },
  inputContainer: {
    padding: `0 ${theme.spacing(1)}`,
  },
}));
