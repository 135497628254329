import { Theme } from "@remo-co/ui-core/src/types";
import { makeStyles } from "@remo-co/ui-core/src/utils/makeStyles";

const useStyles = makeStyles<Theme>((theme) => ({
  tab: {
    minWidth: "unset",
  },
  tabs: {
    justifyContent: "space-between",
    "& .Mui-selected": {
      borderBottom: `1.5px solid ${theme.palette.primary.main}`,
      color: theme.palette.text.primary,
    },
  },
  indicator: { display: "none" },
  hidePanel: {
    margin: theme.spacing(2),
    width: "30px",
    height: "30px",
    padding: 0,
    background: theme.palette.background.secondary,
    border: 0,
    color: theme.palette.text.primary,
    cursor: "pointer",
    borderRadius: "8px",
    "&:focus": {
      outline: "none",
    },
  },
}));

export default useStyles;
